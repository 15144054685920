import React, { useState } from 'react'
import { MdStarRate } from 'react-icons/md'
import { Rating } from 'react-simple-star-rating'
import { useMediaQuery } from 'react-responsive'

export default function Rate({ fiveStarLink }) {
    const [size, setSize] = useState(20)

    const icons = [
        {
            icon: <MdStarRate size={size}/>
        },
        {
            icon: <MdStarRate size={size}/>
        },
        {
            icon: <MdStarRate size={size}/>
        },
        {
            icon: <MdStarRate size={size}/>
        },
        {
            icon: <MdStarRate size={size}/>
        }
    ]

    const [rating, setRating] = useState(0)
    let [hasRated, setHasRated] = useState(false)

    // Catch Rating value
    const handleRating = (rate) => {
        if (hasRated) return
        if (rate === 100) {
            window.location = fiveStarLink;
        }
        setRating(rate)
        setHasRated(true)
    }

    const handleMediaQueryChange = matches => {
        if (matches && size !== 50) return setSize(50)
        if (size === 50 && !matches) return setSize(20)
    }

    const isDesktopOrLaptop = useMediaQuery(
        { minWidth: 400 }, undefined,  handleMediaQueryChange
    );

    return (
        <>
            <div className="rate">
                <Rating 
                    id="ratingComponent"
                    onClick={handleRating}
                    ratingValue={rating}
                    readonly={hasRated}
                    customIcons={icons}/>
            {hasRated && 
                <>
                    <h1>Thank you very much for your rating!</h1>
                    <h1>
                        If you would like to contact me with any feedback or suggestions, <a href="mailto:info@blazemagic.com?Subject=Magician Feedback">click here</a>.
                    </h1>
                </>
            }
            </div>
        </>
    )
}

export const FiveStars = () => {
    const iconSize = 40
    const icons = [
        {
            icon: <MdStarRate size={iconSize}/>
        },
        {
            icon: <MdStarRate size={iconSize}/>
        },
        {
            icon: <MdStarRate size={iconSize}/>
        },
        {
            icon: <MdStarRate size={iconSize}/>
        },
        {
            icon: <MdStarRate size={iconSize}/>
        }
    ]

    return <Rating 
        id="ratingComponent"
        ratingValue={100}
        readonly={true}
        customIcons={icons}/>
}